import _endPoint from "../apis/_endPoint"

export const genderArray = [{ label: 'Male', value: 'MALE' }, { label: 'Female', value: 'FEMALE' }, {label:'Non-binary', value:'NON-BINARY'}, {label:'Trans', value:'TRANS'}, {label:'Other', value:'OTHER'}]

export const roleArray = [
    { value: 'ORGANIZATION_ADMIN', label: 'Organisation Admin' },
    { value: 'SITE_ADMIN', label: 'Site Admin' },
    { value: 'STAFF', label: 'Staff' },
    { value: 'ADMIN', label: 'Admin' }
]

export const taskCommentType = {
    text: 'TEXT',
    image: 'IMAGE'
}

export const checkListItemStatusEnum = {
    report: 'REPORTED',
    complete: 'COMPLETED',
    pending: 'PENDING',
    delete: 'DELETED'
}
export const temperatureTypesEnum = [
    {value:'FRIDGE', label:'Fridge'},
    {value:'FREEZER', label:'Freezer'},
    {value:'CELLAR', label:'Cellar'},
    {value:'FOOD_DELIVERY', label:'Food Delivery'},
    {value:'PROBE_CALIBRATION', label:'Probe Calibration'},
    {value:'COOKING_COOLING', label:'Cooking & Cooling'},
    {value:'HOT_HOLD_DISPLAY', label:'Hot Hold/Display'},
    {value:'REHEATING', label:'Re-Heating'},
    {value:'LEGIONELLA_WATER', label:'Legionella Water'},
    {value:'HOT_WATER', label:'Hot Water'}
]

export const complianceTypesEnum = [
    {value:'ACCIDENT', label:'Accident'},
    {value:'INCIDENT', label:'Incident'},
    {value:'FOOD POISONING', label:'Food Posion'},
    {value:'ALLERGIC REACTION', label:'Allergic Reaction'}
]

export const showSiteBar = ['/dashboard/task', '/dashboard/staff', '/dashboard/temperatureLog', '/dashboard/complianceLog', '/dashboard/departmentManagment', '/dashboard/templates', '/dashboard/taskcategory', '/dashboard/fridge', '/dashboard/freezer', '/dashboard/supplier', '/dashboard/fooditem', '/dashboard/foodItem', '/dashboard/probes']
export const hideSiteBar = ['/edit-template/', '/dashboard/adminTemplate', '/edit-staff/', '/edit-compliance-log/', '/edit-department/', '/edit-taskcategory/', '/edit-fridge/', '/edit-freezer/', '/edit-supplier/', '/edit-foodItem/', '/edit-prob/', '/bulk-assign/', '/edit-task/', '/dashboard/new-task/', '/dashboard/new-compliance-log/', '/pages/account-settings']
export const noSearchParams = [_endPoint.commentTask.commentList]

export const tempIcons = {
    FRIDGE: require('@src/assets/images/icons/fridge.svg').default,
    FREEZER: require('@src/assets/images/icons/freezer.svg').default,
    CELLAR: require('@src/assets/images/icons/cellar.svg').default,
    COOKING_COOLING: require('@src/assets/images/icons/cooking_records.svg').default,
    FOOD_DELIVERY: require('@src/assets/images/icons/food_delivery.svg').default,
    LEGIONELLA_WATER: require('@src/assets/images/icons/legionella.svg').default,
    PROBE_CALIBRATION: require('@src/assets/images/icons/probe_callibration.svg').default,
    REHEATING: require('@src/assets/images/icons/reheating.svg').default,
    HOT_HOLD_DISPLAY: require('@src/assets/images/icons/hot_hold.svg').default,
    HOT_WATER: require('@src/assets/images/icons/hot_hold.svg').default
}