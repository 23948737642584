// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TaskCategoriesAPIs } from '../../../../../src/apis'

const defaultParams = {
	q: "",
	page: 1,
	isParamChanged: true
}

export const getData = createAsyncThunk('appCategory/getData', async params => {

  const response = await TaskCategoriesAPIs.webAppList({
    ...params,
    search: params.q
  })

  return {
    params,
    data: response.data.list,
    allData: response.data.list,
    totalPages: response.data.records
  }
})

export const clearData = createAsyncThunk('appCategory/clearData', () => {
  return {
    data: [],
    allData: [],
    totalPages: 0
  }
})

export const removeData = createAsyncThunk('appCategory/removeData', () => {
	return {
		data: [],
		allData: [],
		totalPages: 0
	}
})


export const setModalData = createAsyncThunk('appInvoice/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
})

export const deleteTaskCategory = createAsyncThunk('appCategory/deleteTaskCategory', async (data, { dispatch }) => {
  const {id, siteID} = data
  await TaskCategoriesAPIs.deleteOne({ taskCategoryID: id })
	await dispatch(getData({...defaultParams, siteID}))
  await dispatch(setModalData({_id : '', isModalOpen: false}))
  return id
})

export const editTaskCatogery = createAsyncThunk('appCategory/edit', async (taskCategoriesData, { dispatch }) => {
	await TaskCategoriesAPIs.updateOne({ body: taskCategoriesData })
	await dispatch(removeData())
})

export const addTaskCatogery = createAsyncThunk('appCategory/addTaskCatogery', async (taskCategoriesData, { dispatch }) => {
  defaultParams.siteID = taskCategoriesData.selectedSiteID
  delete taskCategoriesData.selectedSiteID
  await TaskCategoriesAPIs.createNewOne({ body: taskCategoriesData })
	await dispatch(getData(defaultParams))
})

export const getOneData = createAsyncThunk('appCategory/getOneData', async params => {
	try {
		const response = await TaskCategoriesAPIs.findOne(params)

		return {
			categoriesDetail: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getsimpleListCategory = createAsyncThunk('appCategory/simpleList', async (params) => {
	const response =  await TaskCategoriesAPIs.simpleList({
		 ...params,
			 search: params.q
	  })
  
	  return {
		simpleList: response.data.list
	  }
  })


export const appInvoiceSlice = createSlice({
  name: 'appCategory',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    simpleList: [],
    modalData: {
      isModelOpen :false,
      id: ''
    },
    categoriesDetail: {
      _id: null,
      name: null,
      siteID: [],
      masterTaskID: []
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload.params.isParamChanged) {
        
				state.data = action.payload.data
			} else {    
				state.data = [...state.data, ...action.payload.data]
			}

      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
    .addCase(getOneData.fulfilled, (state, action) => {
      state.categoriesDetail = action.payload.categoriesDetail
    })
    .addCase(removeData.fulfilled, (state, action) => {
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.data = action.payload.data
    })
    .addCase(setModalData.fulfilled, (state, action) => {
      state.modalData = action.payload.modalData
    })
    .addCase(getsimpleListCategory.fulfilled, (state, action) => {
      state.simpleList = action.payload.simpleList
    })
  }
})

export default appInvoiceSlice.reducer
